
@media only screen and (min-width: 150px) and (max-width: 990px) {
  body {
    overflow-x: hidden !important;
  }
  html {
    overflow-x: hidden !important;
  }

  h2 {
    font-size: 25px;
    line-height: 35px;
  }

  .container {
    width: 100% !important;
  }
  .container-2 {
    width: 100% !important;
    padding: 0px 15px;
  }


.loader-bg img{top:46%; left: 42%;}


  .res-center {
    text-align: center;
  }
  .res-full {
    width: 100% !important;
  }
  .res-padding0 {
    padding: 0px;
  }

  .res-hide {
    display: none !important;
  }
  .res-show {
    display: block !important;
  }

  .res-pull-right {
    float: right;
  }
  .res-pull-left {
    float: left;
  }

  .nav-icon1 {
    display: block;
  }

  section {
    padding: 45px 0px;
  }
  .res-padding {
    padding-left: 15px !important;
    padding-right: 20px !important;
  }
  .padding-left0 {
    padding-left: 0px;
  }

  .btn-custom {
    font-size: 12px !important;
  }

  .custom-hd {
    font-size: 26px;
  }
  .custom-hd span {
    margin-top: 0px;
  }

  .text {
    font-size: 14px;
    line-height: 25px;
  }

  .get-in-touch {
    font-size: 13px;
    right: -65px;
  }

  .top-strip .footer-socials {
    display: none;
  }
  .top-strip .col-md-11 {
    width: 100% !important;
  }
  .top-strip .col-md-11 ul {
    float: right;
  }
  .top-strip .col-md-11 ul li {
    float: left;
  }
  .top-strip .col-md-11 ul li:last-child {
    padding-right: 0px !important;
    margin-right: 0px !important;
  }

  .header-fixed.darkHeader {
    height: 105px !important;

  }

  .header-fixed.darkHeader .logo {
    transform: scale(1) !important;
    top: 15px !important;
  }

  .header-fixed.darkHeader .top-strip li a {
    color: #000 !important;
    font-weight: 600;
  }
  .header-fixed.darkHeader .nav-icon1 span {
    background: #000;
  }

  .header-fixed.darkHeader .my-acc-drop .img .fa {
    color: #484848;
  }

  header .logo {
    width: 200px;
  }
  header .container-fluid {
    padding: 0px;
  }
  header .logo {
    top: 10px !important;
  }
  header .nav-icon1 {
    top: -25px;
    left: -15px;
  }
  header .nav-icon1 span {
    background: #fff;
  }

  header .col-md-2 ul {
    display: none;
  }

  .my-acc-drop {
    display: block !important;
    top: -46px !important;
    left: -10px !important;
  }
  .header-fixed.darkHeader .my-acc-drop {
    top: -46px !important;
  }
  .my-acc-drop .dropdown-menu {
    top: 305% !important;
    left: -100% !important;
  }
  .header-fixed.darkHeader .my-acc-drop .dropdown-menu {
    top: 305% !important;
  }
  .my-acc-drop .img .fa {
    top: 2px;
    font-size: 25px;
  }



  header.has-login .col-md-7{width:98.333333% !important;}
  header.has-login .col-md-2{width: 85.666667% !important;}

  nav .login-section {
    display: block;
    width: 100%;
    padding: 0px 25px;
  }
  nav .login-section li {
    float: left !important;
    width: 30% !important;
    margin-right: 15px !important;
  }
  nav .login-section li .btn.org {
    color: #fff !important;
  }

  nav {
    display: block;
    position: fixed !important;
    top: 0px;
    left: 100%;
    background: #fff;
    width: 100%;
    height: 100%;
    z-index: 10000000;
    padding: 20px 0px;
    opacity: 0;
    max-height: 100%;
    overflow: scroll;
    padding-bottom: 252px;
  }
  nav::-webkit-scrollbar {
    width: 2px !important;
  }
  nav.open {
    opacity: 1;
    left: 0px;
  }
  nav .close-nav {
    position: absolute;
    top: 20px;
    right: 20px !important;
    left: auto !important;
  }
  nav .close-nav span {
    background: #000;
  }
  nav ul li {
    width: 100%;
    text-align: left;
    padding: 0px !important;
    margin: 0px !important;
  }
  nav ul li a {
    padding: 15px 25px !important;
    display: block;
    color: #000 !important;
    font-weight: 600 !important;
  }
  nav ul li a label {
    display: block;
  }
  nav ul li a .fa {
    float: right;
  }
  nav ul li a:hover .fa {
    color: #000 !important;
  }

  nav ul li .submenu {
    position: static !important;
    background: #fafafa !important;
    box-shadow: none !important;
    width: 100% !important;
    border-top: 0px !important;
    visibility: inherit !important;
    transform: rotateX(0deg) !important;
    opacity: 1 !important;
    line-height: 25px !important;
    display: none;
  }
  nav .submenu:before {
    display: none;
  }


  nav ul li .submenu a{font-size: 13px !important; padding: 8px 25px !important;}

  .mega-menu {
    width: 100% !important;
  }
  .mega-menu .col5 {
    margin-bottom: 25px;
  }
  .mega-menu img {
    display: none;
  }
  .mega-menu .col {
    width: 100%;
    padding: 15px 25px !important;
    padding-bottom: 0px !important;
    height: auto !important;
  }
  .mega-menu .col li a {
    padding: 8px 0px !important;
    font-weight: 500 !important;
  }
  .mega-menu .col a.a-link {
    padding-left: 0px !important;
  }
  .mega-menu .col .btn {
    margin-left: 0px;
  }
  .mega-menu .col .btn:last-child {
    margin-bottom: 25px !important;
  }

  .home-slider {
    height: auto !important;
  }
  .home-slider .slide {
    height: 551px;
    background-size: cover !important;
  }
  .home-slider .owl-nav {
    display: none !important;
  }

  .home-slider .slide .overlay {
    padding: 0px 35px 0px 15px;
    top: 50%;
  }
  .home-slider .slide .overlay h2 {
    font-size: 30px;
  }
  .home-slider .slide .overlay h3 {
    font-size: 20px;
    line-height: 28px;
  }

  .home-slider img {
    width: 100% !important;
    display: none !important;
  }

  .about {
    padding-top: 0px;
  }
  section.about .col-md-7 {
    padding-right: 15px;
    text-align: center;
  }
  section.about .text {
    text-align: center;
  }
  .about .col-md-5 {
    text-align: center;
  }
  .about img {
    margin-top: 50px;
    margin-left: 0px !important;
  }

  .upcoming-event{padding:25px 0px !important;}

  .upcoming-event .container {
    padding: 0px;
  }

  .upcoming-events .col-md-4 {
    padding: 0px 25px;
  }
  .upcoming-events .col-md-4 .img {
    height: auto;
  }
  .upcoming-events .owl-nav {
    right: 20px !important;
  }

  .gallery {
    padding: 35px 0px;
  }
  .gallery h2 {
    text-align: center !important;
    font-size: 30px;
    line-height: 40px;
  }
  .gallery h2 br {
    display: none !important;
  }
  .gallery img {
    margin-top: 35px;
  }
  .gallery ul {
    float: none !important;
  }

  .bottom-boxes {
    padding: 35px 0px;
  }
  .bottom-boxes .container {
    padding: 0px;
  }
  .bottom-boxes img {
    width: 100%;
    margin-bottom: 15px;
  }
  .bottom-boxes .testimonial {
    height: auto;
    margin: 25px 0px;
    padding: 20px;
  }

  footer {
    background-size: cover !important;
  }
  footer .col-md-3 {
    margin-bottom: 20px;
  }
  footer .col-md-3:last-child {
    margin-bottom: 0px;
  }
  .copyright {
    height: auto;
  }
  .copyright ul {
    float: left;
    margin-top: 15px;
  }

  .gototop {
    bottom: 40px;
  }

  .inner-banner {
    height: 200px;
    padding-bottom: 20%;
    padding-top: 40%;
  }
  .inner-banner h1 {
    font-size: 22px;
    padding: 0px 15px;
    width: 100%;
  }
  .inner-banner h1:before {
    display: none;
  }
  .inner-banner h1:after {
    display: none;
  }

  .inner-banner h1 span:before {
    display: none;
  }
  .inner-banner h1 span:after {
    display: none;
  }

  .inner-banner2 {
    height: auto !important;
    padding-bottom: 150px;
  }

  .inner-banner2 h3{line-height:21px !important;}


  .inner-banner2 .col-md-8 {
    padding: 0px;
  }
  .inner-banner2 .col-md-8 .col-md-4 {
    width: 33%;
    float: left;
  }
  .inner-banner2 .col-md-8 h2 {
    font-size: 30px;
  }
  .inner-banner2 .col-md-8 h3 {
    font-size: 14px;
  }

  .breadcrumb {
    right: 10px;
  }
  .breadcrumb * {
    font-size: 12px;
  }

  .team-section li {
    width: 300px;
  }
  .team-section li:last-child {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .team-box{ width: 100% !important;}
  .team-box .text {
    text-align: center;
  }

  .team-box .img {
    margin: auto;
  }
  .team-box * {
    text-align: center;
  }

  .photo-gallery {
    text-align: center;
  }
  .photo-gallery li {
    width: 300px;
    margin: 0px;
    margin-bottom: 25px;
    float: none;
    display: inline-block;
    vertical-align: top;
  }

  .gallery-filter {
    display: none;
  }

  .photo-gallery-section .tags {
    display: none;
  }

  .video-gallery li {
    width: 300px;
    margin: 0px;
    margin-bottom: 15px;
  }

  .video-popup {
    width: 90%;
  }

  .video-popup .close-video {
    width: 30px;
    height: 30px;
    right: -12px;
  }
  .video-popup .close-video i {
    top: -4px;
  }

.vision{padding-top: 25px;}

  .vision-mission .col-md-6 {
    padding: 30px 0px !important;
  }
  .vision-mission .col-md-6 .text-sml {
    font-size: 16px !important;
  }

  .features {
    padding: 45px 0px !important;
  }
  .features-section {
    padding: 0px 0px !important;
  }
  .features-section .col-md-3 {
    margin-bottom: 55px;
  }
  .features-section .col-md-3:last-child {
    margin-bottom: 0xp;
  }

  .inception {
    width: 100% !important;
  }
  .inception .col-md-6 {
    padding: 0px;
  }
  .inception {
    margin-bottom: 25px;
  }
  .inception img {
    width: 80% !important;
  }

  .inception:nth-child(even) .col-md-6:last-child {
    left: 0px !important;
  }

  .inception img {
    display: none;
  }
  .inception:last-child {
    margin-bottom: 0px !important;
  }

  .autoload .modal-dialog {
    width: 95%;
  }
  .autoload .col-md-5 {
    display: none;
  }
  .autoload .col-md-7 h3 {
    margin-top:05px;
    font-size: 16px;
    margin-top: -11px;
  }


  .autoload .close{ top:-4px; right: -17px;}

  .contact-form-box {
    width: 100%;
    padding: 15px !important;
  }
  .contact-section .col-md-4 {
    padding-bottom: 0px;
  }
  .contact-form h4 {
    width: 80%;
    margin: auto;
    font-size: 16px;
  }
  .contact-form iframe {
    margin-top: 0px;
    height: 250px;
  }

  .form.box2 li {
    width: 100%;
  }
  .form.box3 li {
    width: 100%;
  }

  .faq .col-md-3 {
    display: none;
  }
  .faq .col-md-9 {
    padding: 0px 15px;
    padding-left: 0px;
  }
  .set > a i {
    position: absolute;
    top: 15px;
    right: 10px;
  }

  .login-page {
    padding-top: 20px;

  }
  .login-page .card {
    width: 95%;
    margin-top: 0px;
    padding: 25px 10px;
  }
  .login-page .card form {
    padding: 15px 10px;
  }


  .register{height:874px !important;}

  .register .card .input-group {
    width: 100%;
  }

  .cookies-bar {
    height: auto;
    text-align: center;
    padding: 15px;
    display: none !important;
  }
  .cookies-bar h3 {
    line-height: 25px;
  }
  .cookies-bar .btn {
    float: none !important;
    margin: 10px 0px;
  }

  .cat-details .col-md-8 {
    padding-right: 15px;
  }
  .cat-details .col-md-4 {
    margin-top: 15px;
  }
  .cat-details .col-md-4 .img {
    width: 100%;
    margin-left: 10px;
  }
  .cat-details .col-md-4 .img img {
    width: 100%;
  }
  .cat-details .info2 h4 {
    font-size: 15px;
  }


  .cat-details .info h4 span{font-size:13px !important;}


  .cat-details .info h4 span {
    width: 45%;
  }
  .cat-details .info h3 {
    line-height: 30px;
  }
  .eligible-students li {
    width: 100%;
  }
  .eligible-students * {
    font-size: 14px !important;
  }

  .table-responsive {
    padding-bottom: 15px;
    border: 0px !important;
  }
  .table-responsive::-webkit-scrollbar {
    height: 3px !important;
  }
  .table-responsive::-webkit-scrollbar-thumb {
    background: #ccc !important;
  }

  .multiple-location-box {
    display: block;
  }
  .multiple-location-box .col {
    display: block;
    padding: 0px !important;
  }
  .multiple-location-box.block .col-md-8 .box {
    border-right: 0px;
    padding: 0px !important;
  }
  .multiple-location-box .col:last-child {
    margin-top: 25px;
  }

  .inception .custom-hd {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
  }

  .story-wrap {
    padding-top: 0px;
  }
  .story-wrap .contact-form-box {
    overflow: hidden;
  }
  .story-wrap .contact-form-box h3 {
    font-size: 20px;
    line-height: 28px;
  }

  .upcoming-events-page .col-md-4 {
    width: 100%;
    padding: 0px 15px;
  }

  .test-count .col-md-4 {
    margin-bottom: 25px;
  }
  .test-count .col-md-4:last-child {
    margin-bottom: 0px;
  }

  .book-slot {
    padding-left: 0px !important;
    padding-right: 0px;
  }

  .how-does-it-work .col-md-4 {
    margin-top: 0px;
  }
  .how-does-it-work .col-md-4 .counting {
    position: static;
  }
  .how-does-it-work .col-md-4 {
    margin-bottom: 0px;
  }

  .book-slot .top-wrap .col-md-6 {
    padding: 0px;
    margin-bottom: 55px;
  }
  .book-slot .top-wrap .col-md-6:last-child {
    margin-bottom: 0px;
  }

  .img-shape {
    width: 100%;
  }
  .img-shape img {
    width: 100%;
  }

  .img-shape.margin-bottom{margin-bottom:30px;}


  .request-for-volunteer-img {
    margin-top: 30px;
  }

  .pdf-view {
    width: 100% !important;


}



.features-section .col-md-4{width:100%; margin: 0px 0px 15px 0px !important;}

.photo-gallery-section .col-md-9{margin-bottom: 45px;}

.content-auto{width: 95% !important;}

.upcoming-events-page .col-md-4:last-child{margin-bottom: 10px !important;}

.book-slot .top-wrap{padding:0px !important;}

.modal-dialog{width: 95% !important;}

.modal-body h3{ font-size: 18px !important;}
.autoload .close{ right: -14px ;}

.accordion-container .faq-acc > a{font-size:16px; position: relative;}
.accordion-container .faq-acc > a i{position:absolute; top:15px; right: 5px;}


.upcoming-events-page-full,.cat-details{padding-top:20px;}
.multiple-location-box{margin-bottom:35px;}

.modal-title{font-size: 16px !important;}

.checkbox{font-size: 14px; line-height: 22px;}

.multiple-location-box .col .box{border-right:0px !important;}


/*---admin css start here--*/

.btn2.dark{font-size: 12px !important;}

.card{padding:17px;}

.wrapper header{ width:100%; top:0px; right:0px;}

.wrapper header + div{ margin-top:60px;}

.wrapper .nav-icon1 span{ background:#000 !important;}

.wrapper header .logo{ width:120px; position:relative; top:17px !important; display:block !important;}

.wrapper .right-part{ float:left; width:100%; padding:0px 15px;}

.wrapper .my-acc-drop .dropdown-menu{ top:100% !important; left:-50px !important;}
.wrapper .my-acc-drop { float:left !important; top:0px !important; left:0px !important;}
.wrapper .my-acc-drop li{list-style:none;}
.wrapper header .my-acc-drop li.dropdown{ height:47px;}
.wrapper header .my-acc-drop .dropdown-menu{ left:-29px;}

.set{list-style:none;}

.wrapper header .nav-icon1{ top:15px;}

.nav{padding:0px 0px 25px 0px !important}
.sidebar-nav{ z-index:100000000 !important; width:100% !important; left:inherit !important; right:-100% !important;}
.sidebar-nav .nav-icon1 span{ background:#fff !important;}
.sidebar-nav.open{ right:0px !important;}

.wrapper .nav ul li a{padding:14px 9px !important;}
.wrapper .nav ul li ul li a{padding: 10px 28px !important;}
.wrapper .nav{max-height: 483px !important;}

.form li .form-control{height: 37px !important;}

.mydp{margin-top: -6px !important;}

.dashboard-box{ display:block; margin-top:0px;}


.valunteer-details .row .col-xs-4{ width:40% !important; float:left;}
.valunteer-details .row .col-xs-8{ width:60% !important; float:left;}

.product-count{ display:none;}

.form li.full textarea{height: 100px !important;}


.wrapper header .my-acc-drop .dropdown-toggle label{margin-top: 0px !important;margin-top: 0px !important;
  position: absolute;
  left: -67px;
  max-width: max-content;
  top: 18px;}

  .nav{background: none !important;}
  .nav ul{ display: block;}
  .nav ul li ul{background:none !important;}
  .nav ul li ul a{padding:15px 29px !important; background:none !important;}


  .dataTables_filter label{margin-left: -52px !important;}

  .dataTables_wrapper .dataTables_paginate .paginate_button{padding:5px 10px !important;}

  .admin{height:auto !important; padding-bottom: 25px;}

  .form li .text{font-size: 13px !important;}

  table .btn{padding: 2px 4px !important;}

  .back{margin-top: -56px;}

  .btn2.border{padding:6px 15px !important;}


  .valunteer-details .row .col-xs-4.testimonial-col1{width: 100% !important;}
  .valunteer-details .row .col-xs-8.testimonial-col2{width: 100% !important; margin-top: 10px;}

  .res-hd{font-size: 15px !important; line-height: 24px !important;}

/*---admin css ends here--*/





}

@media only screen and (min-width: 500px) and (max-width: 990px) {
  .photo-gallery li {
    margin: 10px !important;
  }
}









@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) {
  @media only screen and (min-width:150px) and (max-width:990px){


input[type="text"],input[type="email"],input[type="telephone"],input[type="password"],select  {
  /* Remove First */
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;

  /* Then Style */

  background: transparent !important;
  outline: none !important;
}

  }

}

@media only screen and (min-width: 150px) and (max-width: 990px) {
  body {
    overflow-x: hidden !important;
  }
  html {
    overflow-x: hidden !important;
  }

  h2 {
    font-size: 25px;
    line-height: 35px;
  }

  .container {
    width: 100% !important;
  }
  .container-2 {
    width: 100% !important;
    padding: 0px 15px;
  }


.loader-bg img{top:46%; left: 42%;}
.loader-bg span{top:60%; text-align: center; left: 0%; }


  .res-center {
    text-align: center;
  }
  .res-full {
    width: 100% !important;
  }
  .res-padding0 {
    padding: 0px;
  }

  .res-hide {
    display: none !important;
  }
  .res-show {
    display: block !important;
  }

  .res-pull-right {
    float: right;
  }
  .res-pull-left {
    float: left;
  }

  .nav-icon1 {
    display: block;
  }

  section {
    padding: 45px 0px;
  }
  .res-padding {
    padding-left: 15px !important;
    padding-right: 20px !important;
  }
  .padding-left0 {
    padding-left: 0px;
  }

  .btn-custom {
    font-size: 12px !important;
  }

  .custom-hd {
    font-size: 26px;
  }
  .custom-hd span {
    margin-top: 0px;
  }

  .text {
    font-size: 14px;
    line-height: 25px;
  }

  .get-in-touch {
    font-size: 13px;
    right: -65px;
  }

  .top-strip .footer-socials {
    display: none;
  }
  .top-strip .col-md-11 {
    width: 100% !important;
  }
  .top-strip .col-md-11 ul {
    float: right;
  }
  .top-strip .col-md-11 ul li {
    float: left;
  }
  .top-strip .col-md-11 ul li:last-child {
    padding-right: 0px !important;
    margin-right: 0px !important;
  }

  .header-fixed.darkHeader {
    height: 105px !important;
  }
  .header-fixed.darkHeader .logo {
    transform: scale(1) !important;
    top: 10px !important;
  }

  .header-fixed.darkHeader .top-strip li a {
    color: #000 !important;
    font-weight: 600;
  }
  .header-fixed.darkHeader .nav-icon1 span {
    background: #000;
  }

  .header-fixed.darkHeader .my-acc-drop .img .fa {
    color: #484848;
  }

  header .logo {
    width: 200px;
  }
  header .container-fluid {
    padding: 0px;
  }
  header .logo {
    top: 10px !important;
  }
  header .nav-icon1 {
    top: -25px;
    left: -15px;
  }
  header .nav-icon1 span {
    background: #fff;
  }

  header .col-md-2 ul {
    display: none;
  }

  .my-acc-drop {
    display: block !important;
    top: -46px !important;
    left: -10px !important;
  }
  .header-fixed.darkHeader .my-acc-drop {
    top: -46px !important;
  }
  .my-acc-drop .dropdown-menu {
    top: 305% !important;
    left: -100% !important;
  }
  .header-fixed.darkHeader .my-acc-drop .dropdown-menu {
    top: 305% !important;
  }
  .my-acc-drop .img .fa {
    top: 2px;
    font-size: 25px;
  }



  header.has-login .col-md-7{width:98.333333% !important;}
  header.has-login .col-md-2{width: 85.666667% !important;}

  nav .login-section {
    display: block;
    width: 100%;
    padding: 0px 25px;
  }
  nav .login-section li {
    float: left !important;
    width: max-content !important;
    -webkit-width: max-content !important;
    margin-right: 15px !important;
  }
  nav .login-section li .btn.org {
    color: #fff !important;
  }

  nav {
    display: block;
    position: fixed !important;
    top: 0px;
    left: 100%;
    background: #fff;
    width: 100%;
    height: 100%;
    z-index: 10000000;
    padding: 20px 0px;
    opacity: 0;
    max-height: 100%;
    overflow: scroll;
    padding-bottom: 252px;
  }
  nav::-webkit-scrollbar {
    width: 2px !important;
  }
  nav.open {
    opacity: 1;
    left: 0px;
  }
  nav .close-nav {
    position: absolute;
    top: 20px;
    right: 20px !important;
    left: auto !important;
  }
  nav .close-nav span {
    background: #000;
  }
  nav ul li {
    width: 100%;
    text-align: left;
    padding: 0px !important;
    margin: 0px !important;
  }
  nav ul li a {
    padding: 15px 25px !important;
    display: block;
    color: #000 !important;
    font-weight: 600 !important;
  }
  nav ul li a label {
    display: block;
  }
  nav ul li a .fa {
    float: right;
  }
  nav ul li a:hover .fa {
    color: #000 !important;
  }

  nav ul li .submenu {
    position: static !important;
    background: #fafafa !important;
    box-shadow: none !important;
    width: 100% !important;
    border-top: 0px !important;
    visibility: inherit !important;
    transform: rotateX(0deg) !important;
    opacity: 1 !important;
    line-height: 25px !important;
    display: none;
  }
  nav .submenu:before {
    display: none;
  }


  .prev-test{position: static !important;}

  .mega-menu {
    width: 100% !important;
  }
  .mega-menu .col5 {
    margin-bottom: 25px;
  }
  .mega-menu img {
    display: none;
  }
  .mega-menu .col {
    width: 100%;
    padding: 15px 25px !important;
    padding-bottom: 0px !important;
    height: auto !important;
  }
  .mega-menu .col li a {
    padding: 8px 0px !important;
    font-weight: 500 !important;
  }
  .mega-menu .col a.a-link {
    padding-left: 0px !important;
  }
  .mega-menu .col .btn {
    margin-left: 0px;
  }
  .mega-menu .col .btn:last-child {
    margin-bottom: 25px !important;
  }

  .home-slider {
    height: auto !important;
  }
  .home-slider .slide {
    height: 551px;
    background-size: cover !important;
  }
  .home-slider .owl-nav {
    display: none !important;
  }

  .home-slider .owl-dots{ bottom:25px !important; }

  .home-slider .slide .overlay {
    padding: 0px 35px 0px 15px;
    top: 50%;
  }
  .home-slider .slide .overlay h2 {
    font-size: 30px;
  }
  .home-slider .slide .overlay h3 {
    font-size: 20px;
    line-height: 28px;
  }

  .home-slider img {
    width: 100% !important;
    display: none !important;
  }

  .about {
    padding-top: 0px;
  }
  section.about .col-md-7 {
    padding-right: 15px;
    text-align: center;
  }
  section.about .text {
    text-align: center;
  }
  .about .col-md-5 {
    text-align: center;
  }
  .about img {
    margin-top: 50px;
    margin-left: 0px !important;
  }

  .upcoming-event .container {
    padding: 0px;
  }

  .upcoming-events .col-md-4 {
    padding: 0px 25px;
  }
  .upcoming-events .col-md-4 .img {
    height: auto;
  }
  .upcoming-events .owl-nav {
    right: 20px !important;
  }

  .gallery {
    padding: 35px 0px;
  }
  .gallery h2 {
    text-align: center !important;
    font-size: 30px;
    line-height: 40px;
  }
  .gallery h2 br {
    display: none !important;
  }
  .gallery img {
    margin-top: 35px;
  }
  .gallery ul {
    float: none !important;
  }

  .bottom-boxes {
    padding: 35px 0px;
  }
  .bottom-boxes .container {
    padding: 0px;
  }
  .bottom-boxes img {
    width: 100%;
    margin-bottom: 15px;
  }
  .bottom-boxes .testimonial {
    height: auto;
    margin: 25px 0px;
    padding: 20px;
  }

  footer {
    background-size: cover !important;
  }
  footer .col-md-3 {
    margin-bottom: 20px;
  }
  footer .col-md-3:last-child {
    margin-bottom: 0px;
  }
  .copyright {
    height: auto;
  }
  .copyright ul {
    float: left;
    margin-top: 15px;
  }

  .gototop {
    bottom: 40px;
  }

  .inner-banner {
    height: 200px;
    padding-bottom: 20%;
    padding-top: 40%;
  }
  .inner-banner h1 {
    font-size: 22px;
    padding: 0px 15px;
    width: 100%;
  }
  .inner-banner h1:before {
    display: none;
  }
  .inner-banner h1:after {
    display: none;
  }

  .inner-banner h1 span:before {
    display: none;
  }
  .inner-banner h1 span:after {
    display: none;
  }

  .inner-banner2 {
    height: auto !important;
    padding-bottom: 150px;
  }
  .inner-banner2 .col-md-8 {
    padding: 0px;
  }
  .inner-banner2 .col-md-8 .col-md-4 {
    width: 33%;
    float: left;
  }
  .inner-banner2 .col-md-8 h2 {
    font-size: 30px;
  }
  .inner-banner2 .col-md-8 h3 {
    font-size: 14px;
  }

  .breadcrumb-container .back {
    float: right;
    padding-right: 40px;
    padding-top: 75px;
    font-size: 16px;
  }

  .breadcrumb {
    right: 10px;
  }
  .breadcrumb * {
    font-size: 12px;
  }

  .team-section li {
    width: 300px;
  }
  .team-section li:last-child {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .team-box{ width: 100% !important;}
  .team-box .text {
    text-align: center;
  }

  .team-box .img {
    margin: auto;
  }
  .team-box * {
    text-align: center;
  }

  .photo-gallery {
    text-align: center;
  }
  .photo-gallery li {
    width: 300px;
    margin: 0px;
    margin-bottom: 25px;
    float: none;
    display: inline-block;
  }

  .gallery-filter {
    display: none;
  }

  .photo-gallery-section .tags {
    display: none;
  }

  .video-gallery li {
    width: 300px;
    margin: 0px;
    margin-bottom: 15px;
  }

  .video-popup {
    width: 90%;
  }

  .video-popup .close-video {
    width: 30px;
    height: 30px;
    right: -12px;
  }
  .video-popup .close-video i {
    top: -4px;
  }

  .vision-mission .col-md-6 {
    padding: 30px 0px !important;
  }
  .vision-mission .col-md-6 .text-sml {
    font-size: 16px !important;
  }

  .features {
    padding: 45px 0px !important;
  }
  .features-section {
    padding: 0px 0px !important;
  }
  .features-section .col-md-3 {
    margin-bottom: 55px;
  }
  .features-section .col-md-3:last-child {
    margin-bottom: 0xp;
  }

  .inception {
    width: 100% !important;
  }
  .inception .col-md-6 {
    padding: 0px;
  }
  .inception {
    margin-bottom: 25px;
  }
  .inception img {
    width: 80% !important;
  }

  .inception:nth-child(even) .col-md-6:last-child {
    left: 0px !important;
  }

  .inception img {
    display: none;
  }
  .inception:last-child {
    margin-bottom: 0px !important;
  }

  .autoload .modal-dialog {
    width: 95%;
  }
  .autoload .col-md-5 {
    display: none;
  }
  .autoload .col-md-7 h3 {
    margin-top:05px;
  }


  .autoload .close{ top:-22px; right: -17px;}

  .contact-form-box {
    width: 100%;
    padding: 15px !important;
  }
  .contact-section .col-md-4 {
    padding-bottom: 0px;
  }
  .contact-form h4 {
    width: 80%;
    margin: auto;
    font-size: 16px;
  }
  .contact-form iframe {
    margin-top: 0px;
    height: 250px;
  }

  .form.box2 li {
    width: 100%;
  }
  .form.box3 li {
    width: 100%;
  }

  .faq .col-md-3 {
    display: none;
  }
  .faq .col-md-9 {
    padding: 0px 15px;
    padding-left: 0px;
  }
  .set > a i {
    position: absolute;
    top: 15px;
    right: 10px;
  }

  .login-page {
    padding-top: 20px;
  }
  .login-page .card {
    width: 95%;
    margin-top: 0px;
    padding: 25px 10px;
  }
  .login-page .card form {
    padding: 15px 10px;
  }

  .register .card .input-group {
    width: 100%;
  }

  .cookies-bar {
    height: auto;
    text-align: center;
    padding: 15px;
    display: none !important;
  }
  .cookies-bar h3 {
    line-height: 25px;
  }
  .cookies-bar .btn {
    float: none !important;
    margin: 10px 0px;
  }

  .cat-details .col-md-8 {
    padding-right: 15px;
  }
  .cat-details .col-md-4 {
    margin-top: 35px;
  }
  .cat-details .col-md-4 .img {
    width: 100%;
    margin-left: 10px;
  }
  .cat-details .col-md-4 .img img {
    width: 100%;
  }
  .cat-details .info2 h4 {
    font-size: 15px;
  }
  .cat-details .info h4 span {
    width: 45%;
  }
  .cat-details .info h3 {
    line-height: 30px;
  }
  .eligible-students li {
    width: 100%;
  }
  .eligible-students * {
    font-size: 14px !important;
  }

  .table-responsive {
    padding-bottom: 15px;
    border: 0px !important;
  }
  .table-responsive::-webkit-scrollbar {
    height: 3px !important;
  }
  .table-responsive::-webkit-scrollbar-thumb {
    background: #ccc !important;
  }

  .multiple-location-box {
    display: block;
  }
  .multiple-location-box .col {
    display: block;
    padding: 0px !important;
  }
  .multiple-location-box.block .col-md-8 .box {
    border-right: 0px;
    padding: 0px !important;
  }
  .multiple-location-box .col:last-child {
    margin-top: 25px;
  }

  .inception .custom-hd {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
  }

  .story-wrap {
    padding-top: 0px;
  }
  .story-wrap .contact-form-box {
    overflow: hidden;
  }
  .story-wrap .contact-form-box h3 {
    font-size: 20px;
    line-height: 28px;
  }

  .upcoming-events-page .col-md-4 {
    width: 100%;
    padding: 0px 15px;
  }

  .test-count .col-md-4 {
    margin-bottom: 25px;
  }
  .test-count .col-md-4:last-child {
    margin-bottom: 0px;
  }

  .book-slot {
    padding-left: 0px !important;
    padding-right: 0px;
  }

  .how-does-it-work .col-md-4 {
    margin-top: 0px;
  }
  .how-does-it-work .col-md-4 .counting {
    position: static;
  }
  .how-does-it-work .col-md-4 {
    margin-bottom: 0px;
  }

  .book-slot .top-wrap .col-md-6 {
    padding: 0px;
    margin-bottom: 55px;
  }
  .book-slot .top-wrap .col-md-6:last-child {
    margin-bottom: 0px;
  }

  .img-shape {
    width: 100%;
  }
  .img-shape img {
    width: 100%;
  }

  .request-for-volunteer-img {
    margin-top: 30px;
  }

  .pdf-view {
    width: 100% !important;


}


.arrow-down{left:40% !important;}

.features-section .col-md-4{width:100%;}




.sidebar-popup.open{left:5% !important; top:10% !important; width:90% !important; transform:translate(0%,-0%) !important;}


#confirm-modal1 .modal-header span.pull-right{padding-top:25px !important;}


.book-slot .top-wrap{background:#fff !important; box-shadow:none !important;}

.list-margin li{margin-bottom:10px;}


.no-margin-padding{left:0px !important; top: 0px !important; font-size: 13px !important;}


/*---admin css start here--*/

.wrapper header{ width:100%; top:0px; right:0px;}

.wrapper header + div{ margin-top:60px;}

.wrapper .nav-icon1 span{ background:#000 !important;}

.wrapper header .logo{ width:120px; position:relative; top:17px !important; display:block !important;}

.wrapper .right-part{ float:left; width:100%; padding:0px 15px;}

.wrapper .my-acc-drop .dropdown-menu{ top:100% !important; left:-50px !important;}
.wrapper .my-acc-drop { float:left !important; top:0px !important; left:0px !important;}
.wrapper .my-acc-drop li{list-style:none;}
.wrapper header .my-acc-drop li.dropdown{ height:47px;}
.wrapper header .my-acc-drop .dropdown-menu{ left:-29px;}

.set{list-style:none;}

.wrapper header .nav-icon1{ top:15px;}


.sidebar-nav{ z-index:100000000 !important; width:100% !important; left:inherit !important; right:-100% !important;}
.sidebar-nav .nav-icon1 span{ background:#fff !important;}
.sidebar-nav.open{ right:0px !important;}

.dashboard-box{ display:block;}


.valunteer-details .row .col-xs-4{ width:100% !important;}
.valunteer-details .row .col-xs-8{ width:100% !important;}

.product-count{ display:none;}


.btn{position: relative; z-index: 10000;;}

/*---admin css ends here--*/






}

@media only screen and (min-width: 500px) and (max-width: 990px) {
  .photo-gallery li {
    margin: 10px !important;
  }
}
















/*-----------------------------06-6-20---------------------------------*/
@media only screen and (min-width: 150px) and (max-width: 990px) {




.action-btn-mobile{ display:block; margin-bottom:0px; width:100%; height:auto; position:fixed; bottom:0px; left:0px; z-index:1000 !important; background:#fff; z-index:1;
  transition:all 0.4s;  -webkit-box-shadow: 0px 0px 10px rgba(0,0,0,.1);
              -moz-box-shadow: 0px 0px 10px rgba(0,0,0,.1);
                         box-shadow: 0px 0px 10px rgba(0,0,0,.1); padding-left: 0px;
}
.action-btn-mobile li{ width:50%; float:left; text-align:center; list-style:none;}
.action-btn-mobile li a{ display:block; padding:15px 15px; color:#000; text-transform:uppercase; font-weight:600; position:relative; font-size:15px;}
.action-btn-mobile li a:before{ content:''; width:1px; height:20px; position:absolute; top:13px; right:0px; background:rgba(0,0,0,0.1);}
.action-btn-mobile li a.text-pink{ font-weight:bold !important;}
.action-btn-mobile li a img{ width:17px;}


.test-card-listing-head{display: none;}
.test-card ul li{width: 100% !important; display: inline-block;  padding-bottom: 10px;}
.test-card-listing-patent{padding: 0px;}
.test-card ul li .img-sec{margin-top: 10px;}


/*------09*-06-2020-----------*/

.cart-box-footer-left{overflow: hidden;}

.cart-box-footer-left ul li{width: 50% !important; text-align: left; padding-left: 10px !important; margin-bottom: 9px;}
.cart-box-footer-left ul li:last-child{border:none;}

.cart-box{    position: relative; padding-bottom: 52px;}
.cart-box-body-right {position: absolute; bottom: 0px; width: 100%; padding: 8px; left: 0px;}
.cart-box-body-right ul{float: left; padding: 8px; border-top: 1px solid rgba(0,0,0,0.1); width: 100%; text-align: center;}
.cart-box-body-right ul li{font-size: 19px;}

.cart-box-body-right .trash-btn {
    padding-left: 0px !important;

    right: 15px;
  
}
.cart-box-body-right .trash-btn img{font-size: 17px;}
.cart-box-footer-left ul li:nth-child(even){border:none;}
.cart-box-body-right .trash-btn span{width: 37px; height: 37px; padding: 2px;}
.cart-box-footer-right{padding-bottom: 8px; border-top: 1px solid rgba(0,0,0,0.1);}

.mobile-checkout{display: block !important; list-style: none; padding-left: 0px; display: block;
    margin-bottom: 0px;
    width: 100%;
    height: auto;
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 1000 !important;
    background: #fff;
    z-index: 1;
    transition: all 0.4s;
    -webkit-box-shadow: 0px 0px 10px rgba(0,0,0,.1);
    -moz-box-shadow: 0px 0px 10px rgba(0,0,0,.1);
    box-shadow: 0px 0px 10px rgba(0,0,0,.1); padding: 7px 5px;}
.mobile-checkout li{display: inline-block; width: 50%; float: left; text-align: center;}
.mobile-checkout li:first-child {font-size: 18px; font-weight: 600; color: #000; border-right: 1px solid rgba(0,0,0,0.1);}
.mobile-checkout li:first-child span{color: #868686; font-size: 15px; display: block;}
.cart-full{padding-bottom: 85px;}
.cart-full .container{padding: 0px;}
.res-none{display: none;}





/*----------------12-06-2020--------*/



#filter-fixed{display: none;}
#filter-fixed-02 .action-btn-mobile{ opacity:0; visibility:hidden;}
#filter-fixed-02.open .action-btn-mobile{opacity:1; visibility:inherit;}

#filter-fixed-02{width: 100%;
    height: 100% !important;
    max-height: 100% !important;
    overflow: scroll;
    position: fixed !important;
    top: 0px !important;
    right: -1000% !important;
    z-index: 1000000000000;
    background: #f5f5f6;
    opacity: 1; padding: 0px; display: block !important;}
#filter-fixed-02.open{ right:0px !important;}
#filter-fixed-02::-webkit-scrollbar{ width:0px !important;}
#filter-fixed-02::-webkit-scrollbar-thumb{ background:#ccc !important;}
#filter-fixed-02:hover::-webkit-scrollbar{ width:3px !important;}
.action-btn-mobile.filter-action li{width: 50% !important;}

#filter-fixed-02 .product-filter h3{margin-top: 0px; padding: 15px; background: #fff; border-bottom: #e9e9e9; font-size: 18px;}
#filter-fixed-02 .filter-box.top h3 a{display: block !important; font-size: 14px; position: relative; top: 3px; float: right; display: inline-block; text-decoration:none;}


#filter-fixed-02 .filter-box h4.act{background: #f37022; color: #fff !important;}


#filter-fixed-02 .filter-box{ padding-left:10px !important;}


#filter-fixed-02 .filter-box{ padding:0px !important; border:0px; position:static !important;}
#filter-fixed-02 .filter-box h4{ padding:14px 13px !important; font-size:12px !important; color:#000 !important; width:40%;  border-bottom:1px solid rgba(0,0,0,0.05);  border-right:1px solid rgba(0,0,0,0.05); margin:0px !important; background:#f5f5f6;   border-left:2px solid transparent;}

#filter-fixed-02 .filter-box .filter-search{display:none !important;}
#filter-fixed-02 .product-filters .filter-box h4 i{display:none;}

#filter-fixed-02 .product-filters{background:#f5f5f6;}
#filter-fixed-02 .product-filters .filter-box h4{ text-transform:uppercase !important;  line-height:22px;}
#filter-fixed-02 .product-filters .filter-box h4.act{ background:#fff; border-left:2px solid #ff3e85;}


#filter-fixed-02 .filter-box ul{ display:none; padding:0px !important; height: 100%; min-height: 100%;}
#filter-fixed-02 .filter-box ul{ position:absolute; top:65px; left:40%; width:60%; padding:10px; background:#fff;} 
#filter-fixed-02 .filter-box:nth-child(2) ul{ display:block;}
#filter-fixed-02 .filter-box ul li{ padding:2px 10px;}

#filter-fixed-02 .filter-box ul.act{ display:block !important; padding-bottom:50px !important;}

}

@media only screen and (min-width: 150px) and (max-width: 768px) {
  .list-search {text-align: center;}
  .list-search input[type=text] {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 40px;
    font-size: 15px;
    background-color: white;
    background-image: url('../images/searchicon.png');
    background-position: 10px 8px; 
    background-repeat: no-repeat;
    padding: 8px 20px 8px 40px;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
  }
  .list-search input::-webkit-input-placeholder { /* Edge */
    color: #b7b5b5;
    font-size: 13px;
  }
  .list-search input:-ms-input-placeholder { /* Internet Explorer */
    color: #b7b5b5;
    font-size: 13px;
  }
  .list-search input::placeholder {
    color: #b7b5b5;
    font-size: 13px;
  }


}


@media only screen and (min-width: 150px) and (max-width: 990px) {
  .basket-btn{ top: 10px; }
  .dashboard-nav {top: 13px !important;}
}







